
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCeiuke_G6TO3wmhlWhXg3T6zqywDaunPg",
  authDomain: "hockwonline.firebaseapp.com",
  databaseURL: "https://hockwonline-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "hockwonline",
  storageBucket: "hockwonline.appspot.com",
  messagingSenderId: "505187819341",
  appId: "1:505187819341:web:178053794f10d0bc92a104",
  measurementId: "G-E7R64LWXFG"
};